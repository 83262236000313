.MuiInputLabel-root {
  z-index: 0 !important;
}

.ticker {
  display: none;
}

.bottomNav {
  width: 100%;
  position: fixed;
  display: none;
  bottom: 0;
}

.homeBox {
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.headerBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.box1 {
  padding-left: 25px;
  padding-right: 50px;
}

.homeText1 {
  font-size: 2rem !important;
}

.homeImg1 {
  width: 575px;
  height: 512px;
}

.typesList {
  background-color: #049ce4;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.typesContainer {
  margin-top: 25px;
}

.typeBox {
  padding-top: 25px;
  padding-bottom: 50px;
  padding-right: 100px;
  padding-left: 100px;
  text-align: left;
}

.typePaper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
  text-align: center;
}

.childImg {
  width: 80%;
  height: auto;
}

.genDocImg {
  width: 70%;
  height: auto;
}

.aboutUsBox {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.aboutUsContainer {
  margin-top: 25px;
}

.aboutUsTextContainer {
  margin-top: 25px;
  margin-bottom: 25px;
  padding-left: 425px;
  padding-right: 425px;
}

.aboutUsText {
  font-size: x-large !important;
}

.howitworksBox {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #13a7e4;
}

.howitworksContainer {
  margin-top: 25px;
}

.howitworksTextContainer {
  margin-top: 25px;
  margin-bottom: 25px;
  padding-left: 425px;
  padding-right: 425px;
}

.howitworksText {
  font-size: larger !important;
}

.aboutUsLogo {
  margin-top: 50px !important;
}

.ourTeamBox {
  background-color: #4fbcec;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.ourTeamContainer {
  margin-top: 25px;
}

.ourTeamContainer2 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
  text-align: center;
  padding: 25px;
}

.avatarContainer {
  padding: 15px;
}

.dividerVert {
  display: block;
}

.dividerHori {
  display: none;
}

.teamAvatar {
  vertical-align: middle;
  width: 128px;
  height: 128px;
  border-radius: 50%;
}

.footer {
  text-align: center;
}

.footerContainer {
  padding-top: 25px;
  padding-bottom: 65px;
}

.searchContainer {
  margin-top: 20px;
}

/* animation */

.slide-right {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-left {
  -webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-12-12 5:24:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(80%);
    transform: translateX(80%);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes slide-left {
  0% {
    -webkit-transform: translateX(80%);
    transform: translateX(80%);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}


/* mobile view */

@media only screen and (max-width: 1241px) {
  .homeText1 {
    font-size: 1.5rem !important;
  }

  .homeImg1 {
    width: 455px;
    height: 455px;
  }
}

@media only screen and (max-width: 1046px) {
  .homeImg1 {
    width: 371px;
    height: 312px;
  }

  .ourTeamContainer2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    text-align: center;
    padding: 25px;
  }

  .dividerVert {
    display: none;
  }

  .dividerHori {
    display: block;
  }

}

@media only screen and (max-width: 899px) {
  .homeText1 {
    font-size: 1.3rem !important;
  }

  .aboutUsTextContainer {
    margin-top: 25px;
    margin-bottom: 25px;
    padding-left: 175px;
    padding-right: 175px;
  }

  .bottomNav {
    width: 100%;
    position: fixed;
    bottom: 0;
    display: block;
  }

  .bottomNavContainer {
    width: 100%;
    position: sticky;
    bottom: 0;
    justify-content: space-evenly !important;
  }

  .base {
    padding-bottom: 56px;
  }

  .schedDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px 0px 15px 0px;
  }

  .schedBox {
    margin: 25px 0px 0px 0px;
  }

  .schedButton {
    margin: 15px 0px 0px 0px !important;
  }

  .schedText {
    margin: 0px 0px 15px 0px !important;
  }

  .schedPaper {
    padding: 25px;
  }
}

@media only screen and (max-width: 825px) {
  .homeBox {
    padding-left: 75px;
    padding-right: 75px;
    margin-top: 50px;
  }

  .headerBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .header {
    font-size: 1.5rem !important;
  }

  .homeImg1 {
    margin-top: 15px;
    width: 100%;
    height: auto;
  }


}

@media only screen and (max-width: 600px) {
  .box1 {
    text-align: center;
  }

  .aboutUsTextContainer {
    margin-top: 25px;
    margin-bottom: 25px;
    padding-left: 75px;
    padding-right: 75px;
  }
}

@media only screen and (max-width: 425px) {
  .homeText1 {
    font-size: 1.0rem !important;
  }

  .header {
    font-size: 1.3rem !important;
  }

  .homeImg1 {
    margin-top: 15px;
    width: 130%;
    height: auto;
  }

  .avatarContainer {
    padding: 5px;
  }

  .teamAvatar {
    vertical-align: middle;
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }

  .ticker {
    display: flex;
    flex-direction: row;
  }

  .tickerBox {
    margin-top: 10px
  }
}